import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { FormikHelpers, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Input } from '@joovence/ui/components/Input/Input';
import { Checkbox } from '@joovence/ui/components/Checkbox/Checkbox';
import { MUICircularProgress } from '@joovence/ui/libExports';

import { RootState } from '@/store';
import { authActions } from '@/store/auth';

import { ACCOUNT_STATUS, ROUTES, STATUS } from '@/utils/vars';
import { getCookie } from '@/utils/cookie';
import { getServerSideErrors } from '@/utils/format';

import { Grid } from '@/components/common/Grid';
import { Button } from '@/components/common/Button';
import { Link as LinkStyled } from '@/components/common/Link';

import { AccountNotFound } from './AccountNotFound';
import { LoadingStyled } from './Styled';
import { LoginForm, initialValues, validate } from './validation';
import { emailInputProps, passwordInputProps } from './helpers';

export const Login: FC = () => {
  const { t } = useTranslation(['common', 'login']);

  const {
    push,
    replace,
    query: { email: emailQuery }
  } = useRouter();

  const dispatch = useDispatch();
  const { accountStatus, isAuthed, status } = useSelector(
    (state: RootState) => state.auth
  );

  const accountConfirmed = accountStatus === ACCOUNT_STATUS.confirmed;
  const loading =
    status === STATUS.IN_PROGRESS || status === STATUS.NOT_STARTED;
  const buttonText = accountConfirmed
    ? t('common:common.login')
    : t('common:common.continue');

  const onSubmit = (
    values: LoginForm,
    { setSubmitting, setErrors }: FormikHelpers<LoginForm>
  ) => {
    if (!accountConfirmed) {
      dispatch(authActions.authAccountStatusFetchRequested(values));
    } else {
      dispatch(
        authActions.authLoginRequested({
          ...values,
          onError: (error) => {
            setErrors(
              getServerSideErrors({ error, fields: ['email', 'password'] })
            );
          }
        })
      );
    }

    setSubmitting(false);
  };

  const handleValidate = (values: LoginForm) =>
    validate(values, accountConfirmed);

  const {
    values: { email, password, rememberMe },
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue
  } = useFormik<LoginForm>({
    onSubmit,
    initialValues,
    validate: handleValidate
  });

  useEffect(() => {
    if (emailQuery) {
      setFieldValue('email', emailQuery);
      dispatch(
        authActions.authAccountStatusFetchRequested({
          email: emailQuery as string
        })
      );
    } else {
      const emailCookie = getCookie('email');
      if (emailCookie) {
        setFieldValue('email', emailCookie);
      }
    }
  }, [emailQuery]);

  useEffect(() => {
    if (accountStatus === ACCOUNT_STATUS.notConfirmed) {
      push({
        pathname: ROUTES.confirm,
        query: {
          email
        }
      });
    }
  }, [accountStatus]);

  useEffect(() => {
    if (isAuthed) {
      replace(ROUTES.profile);
    }
  }, [isAuthed]);

  return (
    <>
      <h1>{t('common:common.login')}</h1>

      {isAuthed ? (
        <LoadingStyled>
          <span>{t('common:common.connecting')}</span>
          <MUICircularProgress size={24} />
        </LoadingStyled>
      ) : (
        <form onSubmit={handleSubmit}>
          <Grid>
            <div>
              <Input
                name="email"
                id="email"
                label={t('common:common.email')}
                type="email"
                value={email}
                onChange={handleChange}
                errorText={touched.email ? errors.email : ''}
                disabled={loading || accountConfirmed}
                inputProps={emailInputProps}
                autoFocus
                required
                fullWidth
              />
            </div>

            {accountConfirmed && (
              <>
                <div>
                  <Input
                    name="password"
                    id="password"
                    label={t('common:common.password')}
                    type="password"
                    value={password}
                    onChange={handleChange}
                    errorText={touched.password ? errors.password : ''}
                    disabled={loading}
                    inputProps={passwordInputProps}
                    autoFocus
                    required
                    fullWidth
                  />
                </div>

                <div>
                  <Checkbox
                    name="rememberMe"
                    id="rememberMe"
                    label={t('login:login.rememberMe')}
                    checked={rememberMe}
                    onChange={handleChange}
                    disabled={loading}
                  />
                </div>
              </>
            )}

            <Button type="submit" loading={loading} variant="red">
              <span>{buttonText}</span>
            </Button>

            {accountConfirmed && (
              <Link
                href={{ pathname: ROUTES.resetPassword, query: { email } }}
                passHref
              >
                <LinkStyled>{t('login:login.passwordForget')}</LinkStyled>
              </Link>
            )}
          </Grid>
        </form>
      )}

      {accountStatus === ACCOUNT_STATUS.notFound && <AccountNotFound />}
    </>
  );
};
