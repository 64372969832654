import { styled } from '@joovence/ui/config/styled';

export const IconStyled = styled('div')(({ theme }) => ({
  height: 48,
  width: 48,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  margin: `0 auto ${theme.spacing(2)} auto`,

  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.main,
  borderRadius: 24
}));
