import { FC } from 'react';
import { ButtonProps as JoovenceButtonProps } from '@joovence/ui/components/Button/helpers';
import { MUICircularProgress } from '@joovence/ui/libExports';

import { ButtonStyled } from './Styled';

interface ButtonProps extends JoovenceButtonProps {
  loading?: boolean;
}

export const Button: FC<ButtonProps> = ({
  children,
  loading,
  disabled,
  ...props
}) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <ButtonStyled {...props} disabled={loading || disabled}>
      {loading ? <MUICircularProgress size={24} color="secondary" /> : children}
    </ButtonStyled>
  );
};

Button.defaultProps = {
  loading: false
};
