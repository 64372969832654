import { FC, ReactNode } from 'react';

import { LockIcon } from '@/components/icons/LockIcon';

import { IconStyled } from './Styled';

interface PublicLayoutProps {
  children: ReactNode;
}

export const PublicLayout: FC<PublicLayoutProps> = ({ children }) => {
  return (
    <>
      <IconStyled>{LockIcon}</IconStyled>

      {children}
    </>
  );
};
