import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ECONSULT_URL } from '@/utils/config';

import { Button } from '@/components/common/Button';

import { NotFoundStyled } from './Styled';

export const AccountNotFound: FC = () => {
  const { t } = useTranslation('login');

  return (
    <NotFoundStyled>
      <p>{t('login:login.accountNotFound.message')}</p>

      {/* @ts-ignore */}
      <Button href={ECONSULT_URL} variant="lightgreen">
        <span>{t('login:login.accountNotFound.tryMyEligibility')}</span>
      </Button>
    </NotFoundStyled>
  );
};
