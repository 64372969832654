import { FormikErrors } from 'formik';

import {
  composeValidators,
  createEmailValidator,
  createRequiredFieldValidator,
  trimErrors
} from '@/utils/validation';

export interface LoginForm {
  email: string;
  password: string;
  rememberMe: boolean;
}

export type LoginFormErrors = FormikErrors<LoginForm>;

export const initialValues: LoginForm = {
  email: '',
  password: '',
  rememberMe: true
};

const emailValidator = composeValidators<string>(
  createRequiredFieldValidator(),
  createEmailValidator()
);
const passwordValidator = createRequiredFieldValidator();

export const validate = (
  { email, password }: LoginForm,
  accountConfirmed: boolean
): LoginFormErrors => {
  const errors = {} as LoginFormErrors;

  errors.email = emailValidator(email);

  if (accountConfirmed) {
    errors.password = passwordValidator(password);
  }

  return trimErrors(errors);
};
