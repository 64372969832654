import format from 'date-fns/format';

import i18n from '@/utils/i18n';

export type Validator<T> = (field: T) => string | undefined;

const validateEmail = <T extends string>(email: T): boolean => {
  const regexp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regexp.test(email);
};

const validateRequiredField = <T>(field: T): boolean => Boolean(field);

const validateMinLength = <T extends string>(
  field: T,
  minLength: number
): boolean => field.length >= minLength;

const validateMaxLength = <T extends string>(
  field: T,
  maxLength: number
): boolean => field.length <= maxLength;

const validateMinValue = <T extends number>(
  field: T,
  minValue: number
): boolean => field >= minValue;

const validateMaxValue = <T extends number>(
  field: T,
  maxValue: number
): boolean => field <= maxValue;

const dateToNumber = (
  currDate: Date,
  minDate?: Date,
  maxDate?: Date
): { curr: number; min?: number; max?: number } => ({
  curr: currDate.setHours(0, 0, 0, 0),
  min: minDate?.setHours(0, 0, 0, 0),
  max: maxDate?.setHours(0, 0, 0, 0)
});

interface DateRange {
  minDate?: Date;
  maxDate?: Date;
}

const validateDate = <T extends Date>(
  currentDate: T,
  { minDate, maxDate }: DateRange
): boolean => {
  const { curr, min, max } = dateToNumber(currentDate, minDate, maxDate);
  if (min) {
    if (max) {
      return curr >= min && curr <= max;
    }

    return curr >= min;
  }

  if (max) {
    return curr <= max;
  }

  return false;
};

const validatePhone = <T extends string>(field: T): boolean =>
  /^[0-9]+$/.test(field);

const validatePasswordConfirmation = <T>(
  originalPassword: T,
  passwordConfirmation: T
): boolean => originalPassword === passwordConfirmation;

export const createEmailValidator =
  () =>
  <T extends string>(email: T): string | undefined => {
    if (validateEmail(email)) {
      return undefined;
    }

    return i18n.t('validation:validation.email');
  };

export const createRequiredFieldValidator =
  () =>
  <T>(field: T): string | undefined => {
    if (validateRequiredField(field)) {
      return undefined;
    }

    return i18n.t('validation:validation.required');
  };

export const createMinLengthValidator =
  (minLength: number) =>
  <T extends string>(field: T): string | undefined => {
    if (validateMinLength(field, minLength)) {
      return undefined;
    }

    return i18n.t('validation:validation.minLength', { minLength });
  };

export const createMaxLengthValidator =
  (maxLength: number) =>
  <T extends string>(field: T): string | undefined => {
    if (validateMaxLength(field, maxLength)) {
      return undefined;
    }

    return i18n.t('validation:validation.maxLength', { maxLength });
  };

export const createMinValueValidator =
  (minValue: number) =>
  <T extends number>(field: T): string | undefined => {
    if (validateMinValue(field, minValue)) {
      return undefined;
    }

    return i18n.t('validation:validation.minValue', { minValue });
  };

export const createMaxValueValidator =
  (maxValue: number) =>
  <T extends number>(field: T): string | undefined => {
    if (validateMaxValue(field, maxValue)) {
      return undefined;
    }

    return i18n.t('validation:validation.maxValue', { maxValue });
  };

export const createDateValidator =
  (range: DateRange) =>
  <T extends Date | undefined>(field: T): string | undefined => {
    if (!field) {
      return i18n.t('validation:validation.date.invalid');
    }

    if (validateDate(field, range)) {
      return undefined;
    }

    if (range.minDate) {
      const min = format(range.minDate, 'yyyy/MM/dd');

      if (range.maxDate) {
        const max = format(range.maxDate, 'yyyy/MM/dd');
        return i18n.t('validation:validation.date.range', { min, max });
      }

      return i18n.t('validation:validation.date.min', { min });
    }

    if (range.maxDate) {
      const max = format(range.maxDate, 'yyyy/MM/dd');
      return i18n.t('validation:validation.date.max', { max });
    }

    return i18n.t('validation:validation.date.invalid');
  };

export const createPhoneValidator =
  () =>
  <T extends string>(field: T): string | undefined => {
    if (validatePhone(field)) {
      return undefined;
    }

    return i18n.t('validation:validation.phone');
  };

export const createPasswordConfirmationValidator =
  <T>(originalPassword: T) =>
  (passwordConfirmation: T): string | undefined => {
    if (validatePasswordConfirmation(originalPassword, passwordConfirmation)) {
      return undefined;
    }

    return i18n.t('validation:validation.password');
  };

export const composeValidators =
  <T>(...validators: Validator<T>[]) =>
  (field: T): string | undefined =>
    validators.reduce(
      (error: string | undefined, validator) => error || validator(field),
      undefined
    );

type FormError = Record<
  string,
  string | undefined | Record<string, string | undefined>
>;

export const trimErrors = (errors: FormError): FormError =>
  Object.entries(errors).reduce((acc, [key, value]) => {
    if (typeof value === 'object') {
      const trimmed = trimErrors(value) as Record<string, string | undefined>;
      if (Object.keys(trimmed).length) {
        acc[key] = trimmed;
      }
    }

    if (typeof value === 'string') {
      acc[key] = value;
    }

    return acc;
  }, {} as FormError);
