import type { Page } from '@/types/page';

import { PublicLayout } from '@/components/layouts/PublicLayout/PublicLayout';

import { Login } from '@/components/login/Login';

const LoginPage: Page = () => <Login />;

LoginPage.InnerLayout = PublicLayout;

export default LoginPage;
